export default {
    default_color: '#8D38C9',
    alphabets: [
        '#357EC7', // A
        '#C11B17', // B
        '#008080', // C
        '#728C00', // D
        '#0020C2', // E
        '#347C17', // F
        '#D4A017', // G
        '#7D0552', // H
        '#9F000F', // I
        '#E42217', // J
        '#F52887', // K
        '#571B7E', // L
        '#1F45FC', // M
        '#C35817', // N
        '#F87217', // O
        '#41A317', // P
        '#4C4646', // Q
        '#4CC417', // R
        '#C12869', // S
        '#15317E', // T
        '#AF7817', // U
        '#F75D59', // V
        '#FF0000', // W
        '#000000', // X
        '#E9AB17', // Y
        '#8D38C9' // Z
    ],
    numbers: [
        '#FF0000', // 0
        '#8D38C9', // 1
        '#000000', // 2
        '#008080', // 3
        '#0020C2', // 4
        '#41A317', // 5
        '#7D0552', // 6
        '#C35817', // 7
        '#F75D59', // 8
        '#571B7E' // 9
    ]
}
